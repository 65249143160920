<template>
  <v-card style="height:90vh;">
    <Configurator
      viewer-id="Syncobox-Configurator"
      :get-access-token="token()"
      :urn="urn"
      :form-schema="formSchema"
      :only-input="true"
      :read-only="true"
      :loading="loading"
      :navSideWidth="'400px'"
      :model3d="true"
  /></v-card>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig';
import Configurator from '@syncobox/syncobox-product-configurator-viewer';
export default {
  components: { Configurator },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urn: [],
      formSchema: [],
      loading: false,
    };
  },
  watch: {
    model: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.formSchema = this.model.formSchema.components;
      this.urn = [{ urn: this.model.externalId }];
    },
    token() {
      let that = this;
      return function(onSuccess) {
        that.$API.api.pc.forgeToken
          .get(ApiConfig.getJsonConfig)
          .then((res) => res.data)
          .then((res) => onSuccess(res.access_token, res.expires_in));
      };
    },
  },
};
</script>
