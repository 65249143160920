<template>
  <td>
    {{ this.getState() }}
    <v-chip
      v-if="dataItem.modelId !== null && dataItem.status == 5"
      color="success"
      class="ml-2 mr-1"
      small
      outlined
    >
      處理完成
    </v-chip>
    <v-chip
      v-else-if="dataItem.modelId !== null && dataItem.status == 0"
      color="#868686"
      class="ml-2 mr-1"
      small
      outlined
    >
      待提交
    </v-chip>
    <v-chip
      v-else-if="dataItem.modelId !== null && dataItem.status == 1"
      color="#868686"
      class="ml-2 mr-1"
      small
      outlined
    >
      處理中
    </v-chip>
    <v-chip
      v-else-if="dataItem.modelId !== null && dataItem.status == 2"
      color="#868686"
      class="ml-2 mr-1"
      small
      outlined
    >
      已暫停
    </v-chip>
    <v-chip
      v-else-if="dataItem.modelId !== null && dataItem.status == 4"
      color="#868686"
      class="ml-2 mr-1"
      small
      outlined
    >
      已作廢
    </v-chip>
    <v-chip
      v-else-if="dataItem.modelId !== null && dataItem.status == 7"
      color="error"
      class="ml-2 mr-1"
      small
      outlined
    >
      檔案錯誤
    </v-chip>
    <v-chip
      v-else-if="dataItem.modelId !== null && dataItem.status == 3"
      color="#707070"
      class="ml-2 mr-1"
      small
      outlined
    >
      轉檔失敗
    </v-chip>
    <v-tooltip top v-if="dataItem.modelId !== null && dataItem.status == 3">
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          size="22"
          color="error"
          @click="downloadFailedReport()"
          >mdi-alert</v-icon
        >
      </template>
      <span>下載報告</span>
    </v-tooltip>

    <v-menu
      offset-y
      right
      open-on-hover
      v-if="dataItem.messages && dataItem.messages.length > 0"
    >
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" size="22" color="error">mdi-alert</v-icon>
      </template>
      <v-card width="280"
        ><v-container
          ><v-row
            class="ma-0 pa-0"
            v-for="(item, index) in dataItem.messages"
            :key="index"
            ><v-col class="pa-0 pb-1"
              ><span style="font-weight:600;color:#898989">[ERROR]</span></v-col
            ><span> {{ item.fieldName }} - {{ item.text }}</span>
          </v-row></v-container
        ></v-card
      >
    </v-menu>
  </td>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig.js';
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  methods: {
    getState() {
      if (this.dataItem.modelId !== null) {
        var modelStr = '處理規格品 ' + this.dataItem.modelName;
        return modelStr;
      } else if (this.dataItem.billId !== null) {
        var billStr =
          '加值點數 ' +
          this.dataItem.amount +
          ' 點 (有效期限：' +
          this.dataItem.expireDate +
          ')';
        return billStr;
      } else {
        return '退款';
      }
    },
    downloadFailedReport() {
      var companyId = this.$route.params.companyId;
      var processingId = this.dataItem.processingId;

      return new Promise((resolve) => {
        this.$API.api.pc.companyProductProcessing
          .get(companyId, processingId, ApiConfig.getJsonConfig)
          .then((res) => {
            let blob = new Blob([res.data], {
              type: res.headers['content-type'],
            });
            let report = window.URL.createObjectURL(blob);

            let a = document.createElement('a');
            a.setAttribute('href', report);
            a.setAttribute('download', this.dataItem.id);
            a.click();
          })
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            resolve();
          });
      });
    },
  },
};
</script>
