<template>
  <td style="text-align: center">
    {{ this.getState() }}
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data() {
    return {
      versionNum: 0,
    };
  },
  computed: {},
  methods: {
    getState() {
      let items = this.field.split(".");
      let data = this.dataItem;
      items.forEach((item) => {
        data = data[item];
      });
      this.versionNum = data;
      return data;
    },
  },
};
</script>
