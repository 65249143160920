<template>
  <td style="text-align: center">
    <v-chip v-if="this.getState() == '加值'" color="success" small>
      {{ this.getState() }}
    </v-chip>
    <v-chip v-else-if="this.getState() == '使用'" color="primary" small>
      {{ this.getState() }}
    </v-chip>
    <v-chip v-else small>
      {{ this.getState() }}
    </v-chip>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getState() {
      if (this.dataItem.modelId !== null) {
        return '使用';
      } else if (this.dataItem.billId !== null) {
        return '加值';
      } else {
        return '退款';
      }
    },
  },
};
</script>
